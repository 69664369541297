import axios from '@service'
import { INITIATE_GUARANTEE_RETURN } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/** 发起退保 */
export default async function initiateGuaranteeReturnService(payload = {}, config = {}) {
    const param = {
        ...INITIATE_GUARANTEE_RETURN,
        ...config,
        data: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
