import renderUrlLinkCell from '@/weights/customRender/renderUrlLink'

import { timeFilter, withYuanUnitAmountFilter } from '@utils/filter'
import { hasOwnProperty, isEmpty, empty as emptyFilter } from '@utils'

import {
    GUARANTEE_TYPE_MAPPING,
    GUARANTEE_TYPE_BANK_GUARANTEE,
    GUARANTEE_TYPE_GUARANTEE_INSURANCE,

    PROCESS_TYPE_INIT_TYPE,
    PROCESS_TYPE_MAPPING_EMPTY_TEXT,

    RENEWAL_STATUS_SUCCESS
} from '@constant/enum'

// `保额` 字段
const GUARANTEE_AMOUNT_FIELD = 'guaranteeAmount'

// 前置内容项配置
export const ADDON_BEFORE_COLUMNS = [
    {
        dataIndex: 'askGuaranteeAmount',
        label: '金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'operationTypeText',
        label: '办理方式',
        colStyle: {
            textAlign: 'right'
        },
        customRender: val => emptyFilter(val)
    }
]

// 主体字段配置 - 公共部分一
const CONTENT_COLUMNS_COMMON_PART_ONE = [
    {
        dataIndex: GUARANTEE_AMOUNT_FIELD,
        label: '保额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'paymentAmount',
        label: '保费',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'guaranteeStartDateAndEndDateText',
        label: '保险期限',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'policyHolderName',
        label: '投保人',
        customRender: val => emptyFilter(val)
    }
]

// 主体字段配置 - 公共部分二
const CONTENT_COLUMNS_COMMON_PART_TWO = [
    {
        dataIndex: 'projectName',
        label: '工程名称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'projectTypeText',
        label: '工程类型',
        customRender: val => emptyFilter(val)
    }
]

// 主体字段配置 - 公共部分三
const CONTENT_COLUMNS_COMMON_PART_THREE = [
    // {
    //     dataIndex: 'guaranteeStateText',
    //     label: '保函状态',
    //     customRender: val => emptyFilter(val)
    // },
    {
        dataIndex: 'guaranteeType',
        label: '保函类型',
        customRender: val => emptyFilter(GUARANTEE_TYPE_MAPPING[val])
    }
]

const CONTENT_COLUMNS_COLUMNS_FACTORY = {
    // 保证保险
    [GUARANTEE_TYPE_GUARANTEE_INSURANCE]: [
        {
            dataIndex: 'policyNo',
            label: '保单号',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'businessName',
            label: '险种',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'brandName',
            label: '保险品牌',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'organizationName',
            label: '承保机构',
            customRender: val => emptyFilter(val)
        },
        ...CONTENT_COLUMNS_COMMON_PART_ONE,
        {
            dataIndex: 'insuredName',
            label: '被保险人',
            customRender: val => emptyFilter(val)
        },
        ...CONTENT_COLUMNS_COMMON_PART_TWO,
        {
            dataIndex: 'contact',
            label: '联系人',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'mobile',
            label: '联系人电话',
            customRender: val => emptyFilter(val)
        },
        ...CONTENT_COLUMNS_COMMON_PART_THREE,
        {
            dataIndex: 'policyUrl',
            label: '保单文件',
            customRender: renderUrlLinkCell
        },
        {
            dataIndex: 'guaranteeUrl',
            label: '保险凭证',
            customRender: renderUrlLinkCell
        }
    ],

    // 银行保函
    [GUARANTEE_TYPE_BANK_GUARANTEE]: [
        {
            dataIndex: 'guaranteeNo',
            label: '保函编号',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'businessName',
            label: '产品名称',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'brandName',
            label: '出函银行',
            customRender: val => emptyFilter(val)
        },
        {
            dataIndex: 'organizationName',
            label: '银行网点名称',
            customRender: val => emptyFilter(val)
        },
        ...CONTENT_COLUMNS_COMMON_PART_ONE,
        ...CONTENT_COLUMNS_COMMON_PART_TWO,
        {
            dataIndex: 'deliveryTime',
            label: '出函日期',
            customRender: val => timeFilter(val)
        },
        ...CONTENT_COLUMNS_COMMON_PART_THREE,
        {
            dataIndex: 'guaranteeUrl',
            label: '保函文件',
            customRender: renderUrlLinkCell
        }
    ]
}

/** 展示空文本的 getter 策略对象 */
export const EMPTY_TEXT_GETTER_STRATEGY = {
    // 银行保函
    [GUARANTEE_TYPE_BANK_GUARANTEE](dataSource = {}, renewalFlag = false) {
        const { renewalStatus } = dataSource
        if (renewalFlag && RENEWAL_STATUS_SUCCESS !== renewalStatus) {
            return PROCESS_TYPE_MAPPING_EMPTY_TEXT[PROCESS_TYPE_INIT_TYPE]
        }

        const { guaranteeProcessType } = dataSource
        if (hasOwnProperty(PROCESS_TYPE_MAPPING_EMPTY_TEXT, guaranteeProcessType)) {
            return PROCESS_TYPE_MAPPING_EMPTY_TEXT[guaranteeProcessType]
        }
    },

    // 保证保险
    [GUARANTEE_TYPE_GUARANTEE_INSURANCE](dataSource = {}, renewalFlag = false) {
        const emptyText = PROCESS_TYPE_MAPPING_EMPTY_TEXT[PROCESS_TYPE_INIT_TYPE]
        if (isEmpty(dataSource[GUARANTEE_AMOUNT_FIELD])) {
            return emptyText
        }

        const { renewalStatus } = dataSource
        if (renewalFlag && RENEWAL_STATUS_SUCCESS !== renewalStatus) {
            return emptyText
        }
    },

    // 保函类型未知
    guaranteeTypeUnknown() {
        return PROCESS_TYPE_MAPPING_EMPTY_TEXT[PROCESS_TYPE_INIT_TYPE]
    }
}

/** 获取保函信息面板的标题文案 */
export function getGuaranteePanelTitle(dataSource = {}, renewalFlag = false) {
    let title = '保函'

    const { guaranteeType } = dataSource
    if (hasOwnProperty(GUARANTEE_TYPE_MAPPING, guaranteeType)) {
        let subTitle = GUARANTEE_TYPE_MAPPING[guaranteeType]

        if (renewalFlag) {
            subTitle += ' - 续保'

            const { guaranteeStartDateAndEndDateText } = dataSource
            if (!isEmpty(guaranteeStartDateAndEndDateText)) {
                subTitle += guaranteeStartDateAndEndDateText
            }
        }

        title += `（${subTitle}）`
    }

    return title
}

/** 根据保函类型获取对应的主体内容项配置 */
export function getContentColumnsByType(type) {
    return Object.freeze(CONTENT_COLUMNS_COLUMNS_FACTORY[type] || [])
}
